// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "../dashkit/channels";
import "bootstrap-dashkit/dist/js/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/js/all";

import "../application/youtube/contents/preview_modal"

import "../application/dashkit/toggle_filters";
import "../application/dashkit/toggle_episode_info";
import "../application/dashkit/toggle_main_serie_ip_info";
import "../application/dashkit/toggle_series_table";
import "../application/dashkit/tom_select";
import "../application/dashkit/weekpicker";
import "../application/dashkit/modalform";
import "../application/dashkit/contentselection";
import "../application/dashkit/filepond";
import "../application/dashkit/truncator";
import "../application/dashkit/truncatable_tags";
import "../application/dashkit/quill";
import "../application/dashkit/table_checkboxes";
import "../application/dashkit/youtube_scheduling";

// Theme
import "../dashkit/theme/theme";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
