document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("a[id^='toggle-main-serie-ip-info-']").forEach( button => {
    var mainSerieIpId = button.id.replace("toggle-main-serie-ip-info-", "");
    var toggleIconClassList = button.querySelector('i').classList;
    var additionalInfo = document.getElementById("main-serie-ip-info-" + mainSerieIpId);

    toggleInfo(button, toggleIconClassList, additionalInfo);
  });
});

function toggleInfo(button, icon, additionalInfo) {
  button.addEventListener("click", e => {
    e.preventDefault();

    if (additionalInfo.style.display == "table-row") {
      additionalInfo.style.display = "none";
      icon.replace("fe-arrow-up", "fe-arrow-down");
    } else {
      additionalInfo.style.display = "table-row";
      icon.replace("fe-arrow-down", "fe-arrow-up");
    }
  });
}
